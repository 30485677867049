//  icons
import {
  FiYoutube,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
  FiMail,
  FiMapPin,
} from "react-icons/fi";

import Save_Img from "./assets/img/services/noun-logistics-6951984.png";
import Budget_Img from "./assets/img/services/noun-music-7202946.png";
import Guide_Img from "./assets/img/services/noun-farm-7088949.png";
import Earn_Img from "./assets/img/services/noun-construction-2440875.png";

// companies icons

import Yahoo from "./assets/img/brands/Group 11.png";
import USA_today from "./assets/img/brands/UNDP-TH-article-youth-for-sdgs-removebg-preview.png";
import Refinery29 from "./assets/img/brands/Refinery29.png";
import Glamour from "./assets/img/brands/Glamour.png";
import BBC from "./assets/img/brands/BBC.png";
import Berkeley from "./assets/img/brands/Berkeley.png";
import Cision from "./assets/img/brands/Cision.png";
import Fox from "./assets/img/brands/yango-removebg-preview.png";

// projects images
import cypto_tracker from "./assets/img/projects/farming.jpg";
import daily_spending_tracker from "./assets/img/projects/brave nation.jpg";
import debt_pay_off from "./assets/img/projects/csr.jpg";
import housing_cost_calc from "./assets/img/projects/building.jpg";
import mini_budget_sheet from "./assets/img/projects/logistics full.jpg";
import num_Budget_Sheet from "./assets/img/projects/exports.jpg";

// skills images
import SkillImg1 from "./assets/img/skills/html5.png";
import SkillImg2 from "./assets/img/skills/css3.png";
import SkillImg3 from "./assets/img/skills/js.png";
import SkillImg4 from "./assets/img/skills/reactjs.png";
import SkillImg5 from "./assets/img/skills/nextjs.png";
import SkillImg6 from "./assets/img/skills/nodejs.png";
import SkillImg7 from "./assets/img/skills/git.png";
import SkillImg8 from "./assets/img/skills/figma.png";

// testimonial images
import TestiImage1 from "./assets/img/testimonials/433.jpg";
import TestiImage4 from "./assets/img/testimonials/46881.jpg";
import testimonial_2 from "./assets/img/testimonials/588889.jpg";

// navigation
export const navigation = [
  {
    name: "home",
    href: "home",
  },
  {
    name: "services",
    href: "services",
  },
  {
    name: "portfolio",
    href: "portfolio",
  },
  {
    name: "testimonials",
    href: "testimonials",
  },
  {
    name: "contact",
    href: "contact",
  },
];

// social
export const social = [
  {
    icon: <FiYoutube />,
    href: "https://www.tiktok.com/@sharonsrose",
  },
  {
    icon: <FiInstagram />,
    href: "https://www.instagram.com/sharonsrose/",
  },
  {
    icon: <FiLinkedin />,
    href: "https://www.linkedin.com/company/sharonsrose/",
  },
  {
    icon: <FiTwitter />,
    href: "https://twitter.com/sharonsrose",
  },
];

// companies
export const brands = [
  {
    img: Yahoo,
    href: "https://us.yahoo.com/",
  },
  {
    img: USA_today,
    href: "https://www.usatoday.com/",
  },
  // {
  //   img: Glamour,
  //   href: "https://www.glamourmagazine.co.uk/?international",
  // },
  // {
  //   img: BBC,
  //   href: "https://www.bbc.co.uk/news",
  // },
  {
    img: Fox,
    href: "https://www.foxnews.com/",
  },
];

// projects
export const projectsData = [
  {
    id: "1",
    image: mini_budget_sheet,
    name: "Logistics Solutions",
    category: "Logistics",
    content:
      "Helping businesses streamline their supply chains with efficient logistics services.",
  },
  {
    id: "2",
    image: housing_cost_calc,
    name: "Building the Future",
    category: "Building",
    content:
      "Supplying quality building materials to Zambia’s construction industry.",
  },
  {
    id: "3",
    image: daily_spending_tracker,
    name: "Zambian Talent on the Global Stage",
    category: "Entertaiment",
    content:
      "Promoting local artists through our record label, taking Zambian music worldwide.",
  },
  {
    id: "4",
    image: cypto_tracker,
    name: "Sustainable Farming",
    category: "Farming",
    content:
      "Growing Zambia’s future through responsible and innovative agricultural solutions.",
  },
  {
    id: "5",
    image: debt_pay_off,
    name: "Empowering Local Communities",
    category: "CSR",
    content:
      "We work closely with communities, providing jobs and promoting sustainable practices in both our logistics and farming sectors, helping to improve livelihoods across Zambia.",
  },
  {
    id: "6",
    image: num_Budget_Sheet,
    name: "Expanding Agricultural Exports",
    category: "Trade",
    content:
      "We are expanding into international markets, exporting high-quality Zambian agricultural products.",
  },
];

// projects
export const projectsNav = [
  {
    name: "all",
  },
  {
    name: "Logistics",
  },
  {
    name: "Building",
  },
  {
    name: "Farming",
  },
];

// skill
export const skills = [
  {
    image: SkillImg1,
  },
  {
    image: SkillImg2,
  },
  {
    image: SkillImg3,
  },
  {
    image: SkillImg4,
  },
  {
    image: SkillImg5,
  },
  {
    image: SkillImg6,
  },
  {
    image: SkillImg7,
  },
  {
    image: SkillImg8,
  },
];

// services
export const services = [
  {
    icon: <img src={Save_Img} alt="Save_Img" style={{ width: "60%" }} />,
    name: "Logistics",
    description:
      "Efficient and reliable logistics services tailored to your business needs. We handle the transportation of goods across Zambia, ensuring timely and secure deliveries.",
  },
  {
    icon: <img src={Earn_Img} alt="Earn_Img" style={{ width: "60%" }} />,
    name: "Building Materials",
    description:
      "We supply high-quality construction materials, sourced locally and internationally, to meet the demands of Zambia's growing infrastructure sector.",
  },
  {
    icon: <img src={Budget_Img} alt="Earn_Img" style={{ width: "60%" }} />,
    name: "Record Label",
    description:
      "Sharons Rose has recently partnered with Brave Nation Record Label, a home to talented Zambian artists. They provide production, marketing, and distribution services to promote local music on the global stage.",
  },
  {
    icon: <img src={Guide_Img} alt="Guide_Img" style={{ width: "60%" }} />,
    name: "Farming",
    description:
      "Supporting Zambia’s agricultural sector with sustainable farming practices. We cultivate crops while promoting eco-friendly and modern techniques to enhance food security.",
  },
];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage4,
    authorText:
      "I always go for the Sharons Rose Yango cars after an amazing first experience.",
    authorName: "Eric N.",
    authorPosition: "Yango Client, LSK",
  },
  {
    authorImg: testimonial_2,
    authorText:
      "The logistics team does an amazing job of always being on time and I really appreciate having my goods in perfect condition.",
    authorName: "Amanda P.",
    authorPosition: "Trade Kings, LSK",
  },
  {
    authorImg: TestiImage1,
    authorText:
      "I never thought I'd enjoy chicken again but Sharons Farmms are simply unbeatable!",
    authorName: "John T.",
    authorPosition: "Barclays, LSK",
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: "Have a question?",
    subtitle: "We are here to help you.",
    description: "Email me at info@sharonsrose.com",
  },
  {
    icon: <FiMapPin />,
    title: "Current Location",
    subtitle: "Lusaka, Zambia",
    description: "Serving clients country-wide",
  },
];
