import React from "react";
// import services data
import { services } from "../data";

const Services = () => {
  return (
    <section
      id="services"
      className="section"
      style={{ backgroundColor: "orange" }}
    >
      <div className="container mx-auto px-4">
        <div className="flex flex-col items-center text-center">
          <h2
            className="section-title before:content-services relative before:absolute before:opacity-40 before:-top-[2rem] before:-left-28 before:hidden before:lg:block"
            style={{ color: "black" }}
          >
            Your Best Partner Yet
          </h2>
          <p className="subtitle" style={{ color: "black" }}>
            Sharons Rose is a diverse and innovative company with operations
            across four major industries: <b>logistics</b>,{" "}
            <b>building materials</b>, <b>record label</b>, and <b>farming</b>.
            Founded in Lusaka, Zambia, we are committed to excellence and
            innovation, with a strong vision for national{" "}
            <strong>expansion</strong>.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => {
            const { icon, name, description } = service;
            return (
              <div
                className="bg-paragraph p-4 md:p-6 rounded-2xl flex flex-col lg:flex-row justify-center items-center border-2 border-solid border-black"
                key={index}
                style={{ boxShadow: "15px -13px 1px 2px rgba(0,0,0,0.5)" }}
              >
                <div
                  className="text-accent rounded-sm text-[30px] md:text-[10px] mb-4 lg:mb-0 lg:mr-4"
                  style={{ width: "15vh", height: "15vh" }}
                >
                  {icon}
                </div>
                <div className="text-center lg:text-left">
                  <h4 className="text-lg md:text-xl text-black font-medium mb-2">
                    {name}
                  </h4>
                  <p className="content mb-5 text-sm md:text-base">
                    {description}
                  </p>
                  <button className="bg-transparent hover:bg-yellow-600 text-yellow-600 font-bold hover:text-white py-2 px-4 border border-yellow-600 rounded inline-flex items-center">
                    <span>Find out more</span>
                    <svg
                      className="ml-2 w-4 h-4 fill-current"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M14 5l7 7m0 0l-7 7m7-7H3"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Services;
