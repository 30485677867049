import React from "react";

// import hero image
import Hero_Banner from "../assets/img/sharons_logo.png";

const Hero = () => {
  return (
    <section
      id="home"
      className="h-auto lg:h-[85vh] flex flex-col-reverse lg:flex-row items-center bg-primary bg-cover bg-center bg-no-repeat py-16 lg:py-32 overflow-hidden"
    >
      <div className="container mx-auto flex flex-col lg:flex-row items-center lg:items-start">
        {/* Text Content */}
        <div className="flex-1 flex flex-col items-center lg:items-start px-4 lg:pl-20">
          <h1 className="text-3xl leading-tight md:text-5xl md:leading-tight lg:text-6xl lg:leading-[1.2] font-bold md:tracking-[-1px] text-center lg:text-left">
            Shaping Zambia’s Future,
            <br /> One Industry at a Time.
          </h1>
          <p className="pt-4 pb-6 md:pt-6 md:pb-8 max-w-[90%] lg:max-w-[480px] text-base lg:text-lg text-center lg:text-left">
            Sharons Rose is a multifaceted company leading the way in logistics,
            building materials, music production, and farming. Based in Lusaka,
            we are expanding across Zambia, driving growth and innovation in
            every sector we touch.
          </p>
          <a
            className="btn btn-md bg-accent hover:bg-secondary-hover md:btn-lg transition-all"
            href="https://www.facebook.com/SharonsRoseYangoPartner"
          >
            Learn More
          </a>
          <br />
        </div>

        {/* Image */}
        <div className="flex-1 flex justify-center lg:justify-end items-center w-full mb-8 lg:mb-0">
          <img
            className="w-full max-w-[300px] md:max-w-[500px] lg:max-w-[700px] object-cover"
            src={Hero_Banner}
            alt="Banner"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
