import React from "react";

const Project = ({ item }) => {
  return (
    <div key={item.id} className="flex flex-col items-center text-center">
      <div className="mb-8">
        <img
          className="rounded-2xl object-cover"
          src={item.image}
          style={{ width: "50vh", height: "50vh" }}
          alt=""
        />
      </div>
      <p className="capitalize text-accent text-sm mb-3">{item.category}</p>
      <h3 className="text-2xl font-semibold capitalize mb-3 text-black">
        {item.name}
      </h3>
      <p className="text-base max-w-md text-slate-500">{item.content}</p>
    </div>
  );
};

export default Project;
